import { inject } from '@angular/core';
import { CanActivateFn, ParamMap, Router, Routes } from '@angular/router';
import { from } from 'rxjs';

import { authGuard } from './auth.guard';
import uiKitRoutes from './pages/ui-kit-page/ui-kit.routes';

export const redirectTo =
  (redirectToUrl: (params: ParamMap) => string): CanActivateFn =>
    (route) =>
      from(inject(Router).navigateByUrl(redirectToUrl(route.queryParamMap)));

export const routes: Routes = [
  uiKitRoutes,
  {
    path: '',
    loadComponent: () => import('./pages/main-page/main-page.component'),
    canActivate: [authGuard],
    children: [
      {
        path: '',
        canActivate: [redirectTo(() => 'applications?sortField=originDate&sortOrder=-1')],
        children: [],
        pathMatch: 'full',
      },
      {
        path: 'account',
        loadComponent: () => import('./pages/settings-page/account-page/account-page.component'),
      },
      {
        path: 'dashboard',
        loadComponent: () => import('./pages/dashboard-page/dashboard-page.component'),
        canActivate: [authGuard],
        children: [
          {
            path: 'overview-dashboard',
            loadComponent: () =>
              import('./pages/dashboard-page/overview-dashboard-page/overview-dashboard-page.component'),
          },
          {
            path: 'activity',
            loadComponent: () => import('./pages/dashboard-page/activity-page/activity-page.component'),
          },
          {
            path: 'deadlines',
            loadComponent: () => import('./pages/dashboard-page/deadlines-page/deadlines-page.component'),
          },
          {
            path: '**',
            redirectTo: 'overview-dashboard',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'lead',
        loadComponent: () => import('./pages/lead-page/lead-page.component'),
        canActivate: [authGuard],
      },
      {
        path: 'lead/:id',
        loadComponent: () => import('./pages/lead-details-page/lead-details-page.component'),
        canActivate: [authGuard],
        children: [
          {
            path: 'related-contacts',
            loadComponent: () => import('./pages/related-contacts-page/related-contacts-page.component'),
          },
        ],
      },
      {
        path: 'students',
        loadComponent: () => import('./pages/students-page/students-page.component'),
        canActivate: [authGuard],
      },
      {
        path: 'students/:id',
        loadComponent: () => import('./pages/student-details-page/student-details-page.component'),
        canActivate: [authGuard],
        children: [
          {
            path: 'overview-students',
            loadComponent: () => import('./pages/student-details-page/overview-students/overview-students.component'),
          },
          {
            path: 'registry',
            loadComponent: () => import('./pages/student-details-page/registry/registry.component'),
          },
          {
            path: 'family',
            loadComponent: () => import('./pages/student-details-page/family/family.component'),
          },
          {
            path: 'health',
            loadComponent: () => import('./pages/student-details-page/health/health.component'),
          },
          {
            path: 'education',
            loadComponent: () => import('./pages/student-details-page/education/education.component'),
          },
          {
            path: 'isee',
            loadComponent: () => import('./pages/student-details-page/isee/isee.component'),
          },
          {
            path: 'documents-students',
            loadComponent: () => import('./pages/student-details-page/documents-students/documents-students.component'),
          },
          {
            path: 'historical',
            loadComponent: () => import('./pages/student-details-page/historical/historical.component'),
          },
          {
            path: 'contacts',
            loadComponent: () => import('./pages/student-details-page/historical/historical.component'),
          },
          {
            path: 'related-contacts',
            loadComponent: () => import('./pages/related-contacts-page/related-contacts-page.component'),
          },
          {
            path: '**',
            redirectTo: 'overview-students',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'parents',
        loadComponent: () => import('./pages/parents-page/parents-page.component'),
        canActivate: [authGuard],
      },
      {
        path: 'parents/:id',
        loadComponent: () => import('./pages/parents-details-page/parents-details-page.component'),
        canActivate: [authGuard],
        children: [
          {
            path: 'overview-parents',
            loadComponent: () => import('./pages/parents-details-page/overview-parents/overview-parents.component'),
          },
          {
            path: 'profile',
            loadComponent: () => import('./pages/parents-details-page/profile/profile.component'),
          },
          {
            path: 'documents-parents',
            loadComponent: () => import('./pages/parents-details-page/documents-parents/documents-parents.component'),
          },
          {
            path: 'related-contacts',
            loadComponent: () => import('./pages/related-contacts-page/related-contacts-page.component'),
          },
          {
            path: '**',
            redirectTo: 'overview-parents',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'applications',
        loadComponent: () => import('./pages/applications-page/applications-page.component'),
        canActivate: [authGuard],
      },
      {
        path: 'applications/:id',
        loadComponent: () => import('./pages/application-details-page/application-details-page.component'),
        canActivate: [authGuard],
      },
      {
        path: 'programs',
        loadComponent: () => import('./pages/programs-page/programs-page.component'),
        canActivate: [authGuard],
      },
      {
        path: 'communications',
        loadComponent: () => import('./pages/communications-page/communications-page.component'),
        canActivate: [authGuard],
        children: [
          {
            path: ':threadId',
            loadComponent: () => import('./pages/communication-details-page/communication-details-page.component'),
          },
        ],
      },
      {
        path: 'settings',
        loadComponent: () => import('./pages/settings-page/settings-page.component'),
        canActivate: [authGuard],
        children: [
          {
            path: 'users',
            loadComponent: () => import('./pages/settings-page/widget/users/users.component'),
          },
          {
            path: 'procedure',
            loadComponent: () => import('./pages/settings-page/procedures-page/procedures-page.component'),
          },
          {
            path: 'contests',
            loadComponent: () => import('./pages/settings-page/contests-page/contests-page.component'),
            children: [
              {
                path: ':contestId',
                loadComponent: () =>
                  import('./pages/settings-page/contests-page/contest-details/contest-details.component'),
              },
            ],
          },
          {
            path: '',
            redirectTo: 'users',
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
