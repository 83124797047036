import { TranslateLoader } from '@ngx-translate/core';
import { from } from 'rxjs';

export class DynamicImportLoader implements TranslateLoader {
  // eslint-disable-next-line class-methods-use-this
  getTranslation(lang: string) {
    const fetchIt = async () => {
      const arr = await Promise.all([import('./en.json'), import('./it.json')]);
      return arr.reduce((accumulator, currentValue) => ({ ...accumulator, ...currentValue.default }), {});
    };

    const fetchEn = async () => (await import('./en.json')).default;

    const json$ = lang === 'it' ? fetchIt() : fetchEn();

    return from(json$.then((x) => x));
  }
}
