import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { AuthenticationService } from './services/authentication.service';
import { environment } from '../environments/environment';

export const authGuard: CanActivateFn = async ({ url }) => {
  const authenticationService = inject(AuthenticationService);

  try {
    authenticationService.currentUser = await firstValueFrom(authenticationService.getMe());
    return true;
  } catch (e) {
    if (environment.production) {
      window.location.href = `${environment.loginFlowPath}?redirectUri=${url}`;
    } else {
      window.location.href = `${environment.internalLoginFlowPath}?redirectUri=${url}`;
    }
    return false;
  }
};
